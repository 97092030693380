const useFlipTheCoin = () => {
	const { data: bonusesData, refresh: refreshBonusesData } = useGetBonusesData();

	const typeValue = computed(() => bonusesData.value?.flipTheCoin?.reasonValue || "");
	const limitValue = computed(() => bonusesData.value?.flipTheCoin?.reasonType || "");
	const isFlipTheCoinEnabled = computed(() => !!bonusesData.value?.flipTheCoin);
	const isFlipTheCoinVisible = computed(() => bonusesData.value?.flipTheCoin?.isVisibleToFront);
	const isFlipTheCoinAvailable = computed(() => bonusesData.value?.flipTheCoin?.available);
	const isLimitOffer = computed(() => ["limit_by_promo_offers", "no_offers_found"].includes(limitValue.value));

	const { duration, durationLeft, reset } = useCountdown({ timestamp: typeValue.value });

	const handleModalClose = () => {
		refreshBonusesData();
	};

	return {
		typeValue,
		limitValue,
		durationLeft,
		duration,
		reset,
		isFlipTheCoinEnabled,
		isFlipTheCoinVisible,
		isFlipTheCoinAvailable,
		isLimitOffer,
		handleModalClose,
		refreshBonusesData
	};
};

export default useFlipTheCoin;
